import { combineReducers } from 'redux';
import {
    FETCH_FEATURES_ERROR,
    FETCH_FEATURES_IN_PROGRESS,
    FETCH_FEATURES_SUCCESS,
    FETCH_OWNER_COLLECTIONS_ERROR,
    FETCH_OWNER_COLLECTIONS_IN_PROGRESS,
    FETCH_OWNER_COLLECTIONS_SUCCESS,
    FETCH_OWNER_LISTINGS_ERROR,
    FETCH_OWNER_LISTINGS_IN_PROGRESS,
    FETCH_OWNER_LISTINGS_SUCCESS,
    FETCH_OWNER_NFT_S_ERROR,
    FETCH_OWNER_NFT_S_IN_PROGRESS,
    FETCH_OWNER_NFT_S_SUCCESS,
} from '../constants/navBar';

const collections = (state = {
    inProgress: false,
    list: [],
    count: 0,
}, action) => {
    switch (action.type) {
    case FETCH_OWNER_COLLECTIONS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_OWNER_COLLECTIONS_SUCCESS:
        return {
            ...state,
            inProgress: false,
            list: action.result,
            count: action.count,
        };
    case FETCH_OWNER_COLLECTIONS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const nfts = (state = {
    inProgress: false,
    list: [],
    count: 0,
}, action) => {
    switch (action.type) {
    case FETCH_OWNER_NFT_S_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_OWNER_NFT_S_SUCCESS:
        return {
            ...state,
            inProgress: false,
            list: action.result,
            count: action.count,
        };
    case FETCH_OWNER_NFT_S_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const listings = (state = {
    inProgress: false,
    list: [],
    count: 0,
}, action) => {
    switch (action.type) {
    case FETCH_OWNER_LISTINGS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_OWNER_LISTINGS_SUCCESS:
        return {
            ...state,
            inProgress: false,
            list: action.result,
            count: action.count,
        };
    case FETCH_OWNER_LISTINGS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const features = (state = {
    inProgress: false,
    list: [],
    count: 0,
}, action) => {
    switch (action.type) {
    case FETCH_FEATURES_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_FEATURES_SUCCESS:
        return {
            ...state,
            inProgress: false,
            list: action.result,
            count: action.count,
        };
    case FETCH_FEATURES_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    collections,
    nfts,
    listings,

    features,
});
