import { config } from '../../config';

const DATA_LAYER = config.API_URL;
export const urlConnectMediaNode = (URL) => `${URL}/api/user/connect-bc-account`;
export const urlVerifyMediaNode = (URL, userId) => `${URL}/api/user/${userId}/verify-bc-account`;
export const urlFetchProfileDetails = (URL) => `${URL}/api/user/profile/details`;
export const urlFetchMediaNodeStatus = (URL) => `${URL}/api/status`;
export const urlFetchMediaNodeAccessToken = (URL) => `${URL}/api/user/auth/refresh-token`;
export const urlFetchLiveStreams = (URL) => `${URL}/api/live-streams`;
export const urlFetchLiveStreamDetails = (URL, ID) => `${URL}/api/live-streams/${ID}/config`;
export const urlFetchLiveStreamStatus = (URL, ID) => `${URL}/api/live-streams/${ID}/status`;
export const urlFetchLiveStreamWatchUrl = (URL, ID) => `${URL}/api/live-streams/${ID}/watch-url`;
export const urlstreamStart = (URL, ID) => `${URL}/api/live-streams/${ID}/start`;
export const urlstreamStop = (URL, ID) => `${URL}/api/live-streams/${ID}/stop`;
export const urlupdateLiveStreamDestination = (URL, ID) => `${URL}/api/live-streams/${ID}`;
export const urladdStreamText = (URL, ID) => `${URL}/api/live-streams/${ID}/live-text`;
export const urladdStreamDefaultFile = (URL, userid) => `${URL}/runner/users/${userid}/add-default-stream`;
export const urlgetStreamDefaultFile = (URL, userid) => `${URL}/runner/users/${userid}/get-default-stream`;

// TV Live Streams
export const urlFetchTvLiveStreams = (skip, limit) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${DATA_LAYER}/live-streams?${params.join('&')}`;
};
export const urlFetchTvLiveStream = (ID) => `${DATA_LAYER}/live-streams/${ID}`;
export const urladdTvLiveStream = () => `${DATA_LAYER}/live-streams`;
export const urlfetchTvLiveStreamUrl = (ID) => `${DATA_LAYER}/live-streams/${ID}/url`;
export const urlpublishTvLiveStream = (ID) => `${DATA_LAYER}/live-streams/${ID}/publish`;
export const urlUnpublishTvLiveStream = (ID) => `${DATA_LAYER}/live-streams/${ID}/unpublish`;
