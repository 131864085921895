import { combineReducers } from 'redux';
import {
    CREATE_CHANNEL_CONTRACT_DIALOG_SHOW,
    CREATE_CHANNEL_CONTRACT_DIALOG_HIDE,
    FETCH_CHANNEL_CONTRACT_AVAILABILITY_IN_PROGRESS,
    FETCH_CHANNEL_CONTRACT_AVAILABILITY_SUCCESS,
    FETCH_CHANNEL_CONTRACT_AVAILABILITY_ERROR,
    CHANNEL_CONTRACT_USER_NAME_SET,
    CHANNEL_CONTRACT_USER_NAME_STATUS_SET,
    FETCH_CHANNEL_CONTRACT_IN_PROGRESS,
    FETCH_CHANNEL_CONTRACT_SUCCESS,
    FETCH_CHANNEL_CONTRACT_ERROR,
    FETCH_CHANNEL_CONTRACT_INFO_IN_PROGRESS,
    FETCH_CHANNEL_CONTRACT_INFO_SUCCESS,
    FETCH_CHANNEL_CONTRACT_INFO_ERROR,
    CHANNEL_CONTRACT_CHANGE_DIALOG_SHOW,
    CHANNEL_CONTRACT_CHANGE_DIALOG_HIDE,
    CHANNEL_CONTRACT_SETTINGS_TAB_SET,
    SETTINGS_CHANNEL_CONTRACT_NAME_SET,
    SETTINGS_CHANNEL_CONTRACT_DESCRIPTION_SET,
    SETTINGS_CHANNEL_CONTRACT_ADDRESS_SET,
    SETTINGS_CHANNEL_CONTRACT_ALLOW_PUBLISHERS_SET,
    SETTINGS_CHANNEL_CONTRACT_IMAGE_SET,
    CHANNEL_CONTRACT_USERNAME_SETTINGS_SET,
    CHANNEL_CONTRACT_SAVE_BUTTON_ENABLE_SET,
    FETCH_CHANNEL_ASSETS_IN_PROGRESS,
    FETCH_CHANNEL_ASSETS_SUCCESS,
    FETCH_CHANNEL_ASSETS_ERROR,
    CHANNEL_ASSETS_TAB_SET,
} from '../../constants/channelContract';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../config';
import { DISCONNECT_SET } from '../../constants/account';

const createChannelContractDialog = (state = {
    open: false,
}, action) => {
    switch (action.type) {
    case CREATE_CHANNEL_CONTRACT_DIALOG_SHOW:
        return {
            open: true,
        };
    case CREATE_CHANNEL_CONTRACT_DIALOG_HIDE:
        return {
            open: false,
        };
    default:
        return state;
    }
};

const channelContractAvailability = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case FETCH_CHANNEL_CONTRACT_AVAILABILITY_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_CHANNEL_CONTRACT_AVAILABILITY_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_CHANNEL_CONTRACT_AVAILABILITY_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const channelContractUserName = (state = '', action) => {
    if (action.type === CHANNEL_CONTRACT_USER_NAME_SET) {
        return action.value;
    } else if (action.type === CREATE_CHANNEL_CONTRACT_DIALOG_HIDE) {
        return '';
    }

    return state;
};

const channelContractUsernameStatus = (state = true, action) => {
    if (action.type === CHANNEL_CONTRACT_USER_NAME_STATUS_SET) {
        return action.value;
    } else if (action.type === CREATE_CHANNEL_CONTRACT_DIALOG_HIDE) {
        return true;
    }

    return state;
};

const channelContractsList = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case FETCH_CHANNEL_CONTRACT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_CHANNEL_CONTRACT_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        }
    case FETCH_CHANNEL_CONTRACT_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const channelContractInfo = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_CHANNEL_CONTRACT_INFO_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_CHANNEL_CONTRACT_INFO_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_CHANNEL_CONTRACT_INFO_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const channelContractChangeDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case CHANNEL_CONTRACT_CHANGE_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case CHANNEL_CONTRACT_CHANGE_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };

    default:
        return state;
    }
};

const channelContractSettingsTab = (state = 'general', action) => {
    if (action.type === CHANNEL_CONTRACT_SETTINGS_TAB_SET) {
        return action.value;
    }

    return state;
};

const settingsChannelContractName = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case SETTINGS_CHANNEL_CONTRACT_NAME_SET:
        return {
            value: action.value,
        };
    case FETCH_CHANNEL_CONTRACT_INFO_SUCCESS:
        return {
            value: action.value && action.value.channel_name,
        };

    default:
        return state;
    }
};

const settingsChannelContractDescription = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case SETTINGS_CHANNEL_CONTRACT_DESCRIPTION_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case FETCH_CHANNEL_CONTRACT_INFO_SUCCESS:
        return {
            value: action.value && action.value.description,
            valid: true,
        };

    default:
        return state;
    }
};

const settingsChannelContractAddress = (state = {
    value: [],
    switch: false,
}, action) => {
    switch (action.type) {
    case SETTINGS_CHANNEL_CONTRACT_ADDRESS_SET:
        return {
            ...state,
            value: action.value,
        };
    case SETTINGS_CHANNEL_CONTRACT_ALLOW_PUBLISHERS_SET:
        return {
            ...state,
            switch: action.value,
        };
    case FETCH_CHANNEL_CONTRACT_INFO_SUCCESS:
        return {

            ...state,
            value: action.value && action.value.publishers,
            switch: !!(action.value && action.value.publishers && action.value.publishers.length),
        };

    default:
        return state;
    }
};

const settingsChannelContractImage = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case SETTINGS_CHANNEL_CONTRACT_IMAGE_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const channelContractUserNameSettings = (state = '', action) => {
    if (action.type === CHANNEL_CONTRACT_USERNAME_SETTINGS_SET) {
        return action.value;
    } else if (action.type === FETCH_CHANNEL_CONTRACT_INFO_SUCCESS) {
        return (action.value && (action.value.username || action.value.custom_id)) || '';
    }

    return state;
};

const channelContractSaveButtonEnable = (state = false, action) => {
    switch (action.type) {
    case CHANNEL_CONTRACT_SAVE_BUTTON_ENABLE_SET:
        return action.value;
    default:
        return state;
    }
};

const channelAssets = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case FETCH_CHANNEL_ASSETS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_CHANNEL_ASSETS_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        }
    case FETCH_CHANNEL_ASSETS_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const channelAssetsTab = (state = {
    value: 'all',
}, action) => {
    switch (action.type) {
    case CHANNEL_ASSETS_TAB_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

export default combineReducers({
    createChannelContractDialog,
    channelContractAvailability,
    channelContractUserName,
    channelContractUsernameStatus,
    channelContractsList,
    channelContractInfo,
    channelContractChangeDialog,

    channelContractSettingsTab,

    settingsChannelContractName,
    settingsChannelContractDescription,
    settingsChannelContractAddress,

    settingsChannelContractImage,
    channelContractUserNameSettings,

    channelContractSaveButtonEnable,

    channelAssets,
    channelAssetsTab,
});
