import { combineReducers } from 'redux';
import {
    ADD_LEASE_DIALOG_HIDE,
    ADD_LEASE_DIALOG_SHOW,
    DELETE_LEASE_DIALOG_HIDE,
    DELETE_LEASE_DIALOG_SHOW,
    DEPOSIT_LEASE_DATA_SET,
    LEASE_BUTTON_VALUE_SET,
    LEASE_HOURS_SET,
    LEASE_LIST_FETCH_ERROR,
    LEASE_LIST_FETCH_IN_PROGRESS,
    LEASE_LIST_FETCH_SUCCESS,
    REGISTER_LEASE_AMOUNT_VALUE_SET,
    REGISTER_LEASE_CONTACT_VALUE_SET,
    REGISTER_LEASE_CPUS_VALUE_SET,
    REGISTER_LEASE_DENOM_VALUE_SET,
    REGISTER_LEASE_DEPOSIT_AMOUNT_VALUE_SET,
    REGISTER_LEASE_DESCRIPTION_VALUE_SET,
    REGISTER_LEASE_DIALOG_HIDE,
    REGISTER_LEASE_DIALOG_SHOW,
    REGISTER_LEASE_MONIKER_VALUE_SET,
    REGISTER_LEASE_RAM_GB_VALUE_SET,
    REGISTER_LEASE_STORAGE_GB_VALUE_SET,
    REGISTER_LEASE_URL_VALUE_SET,
    UPDATE_LEASE_DATA_SET,
} from '../../constants/mediaNodes/lease';
import { DEFAULT_SKIP, DEFAULT_TABLE_LIMIT, config } from '../../config';
import { tokensList } from '../../utils/defaultOptions';

const leaseButtonValue = (state = {
    show: false,
}, action) => {
    switch (action.type) {
    case LEASE_BUTTON_VALUE_SET:
        return {
            show: action.value,
        };
    default:
        return state;
    }
};

const leaseList = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_TABLE_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case LEASE_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case LEASE_LIST_FETCH_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
        };
    }
    case LEASE_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const LeaseDialog = (state = {
    value: {},
    open: false,
    extend: false,
}, action) => {
    switch (action.type) {
    case ADD_LEASE_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
            extend: action.extend,
        };
    case ADD_LEASE_DIALOG_HIDE:
        return {
            open: false,
            value: {},
            extend: false,
        };
    default:
        return state;
    }
};

const hours = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case LEASE_HOURS_SET:
        return {
            value: action.value,
        };
    case ADD_LEASE_DIALOG_HIDE:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const deleteLeaseDialog = (state = {
    value: {},
    open: false,
}, action) => {
    switch (action.type) {
    case DELETE_LEASE_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case DELETE_LEASE_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

const registerLeaseDialog = (state = {
    value: {},
    open: false,
    update: false,
    deposit: false,
}, action) => {
    switch (action.type) {
    case REGISTER_LEASE_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
            update: action.update,
            deposit: action.deposit,
        };
    case REGISTER_LEASE_DIALOG_HIDE:
        return {
            open: false,
            value: {},
            update: false,
        };
    default:
        return state;
    }
};

const depositLeaseData = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case DEPOSIT_LEASE_DATA_SET:
        return {
            value: action.value,
        };
    case REGISTER_LEASE_DIALOG_HIDE:
        return {
            value: {},
        };
    default:
        return state;
    }
};

const updateLeaseData = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case UPDATE_LEASE_DATA_SET:
        return {
            value: action.value,
        };
    case REGISTER_LEASE_DIALOG_HIDE:
        return {
            value: {},
        };
    default:
        return state;
    }
};

const registerLeaseURL = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case REGISTER_LEASE_URL_VALUE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case UPDATE_LEASE_DATA_SET:
        return {
            value: action.value && action.value.url,
            valid: true,
        };
    case REGISTER_LEASE_DIALOG_HIDE:
        return {
            ...state,
            value: '',
            valid: true,
        };
    default:
        return state;
    }
};

const registerLeaseCPUS = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case REGISTER_LEASE_CPUS_VALUE_SET:
        return {
            value: action.value,
        };
    case UPDATE_LEASE_DATA_SET:
        return {
            value: action.value && action.value.hardware_specs &&
                action.value.hardware_specs.cpus,
        };
    case REGISTER_LEASE_DIALOG_HIDE:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const registerLeaseRamGB = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case REGISTER_LEASE_RAM_GB_VALUE_SET:
        return {
            value: action.value,
        };
    case UPDATE_LEASE_DATA_SET:
        return {
            value: action.value && action.value.hardware_specs &&
                action.value.hardware_specs.ram_in_gb,
        };
    case REGISTER_LEASE_DIALOG_HIDE:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const registerLeaseStorageGB = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case REGISTER_LEASE_STORAGE_GB_VALUE_SET:
        return {
            value: action.value,
        };
    case UPDATE_LEASE_DATA_SET:
        return {
            value: action.value && action.value.hardware_specs &&
                action.value.hardware_specs.storage_in_gb,
        };
    case REGISTER_LEASE_DIALOG_HIDE:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const registerLeaseDenom = (state = {
    value: tokensList[0],
    options: tokensList,
}, action) => {
    switch (action.type) {
    case REGISTER_LEASE_DENOM_VALUE_SET:
        return {
            ...state,
            value: action.value,
        };
    case UPDATE_LEASE_DATA_SET:
        return {
            value: {
                value: action.value && action.value.price_per_hour &&
                action.value.price_per_hour.denom,
                label: action.value && action.value.price_per_hour &&
                action.value.price_per_hour.denom === 'uflix' ? 'FLIX' : action.value.price_per_hour.denom,
            },
        };
    case REGISTER_LEASE_DIALOG_HIDE:
        return {
            value: tokensList[0],
            options: tokensList,
        };
    default:
        return state;
    }
};

const registerLeaseAmount = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case REGISTER_LEASE_AMOUNT_VALUE_SET:
        return {
            value: action.value,
        };
    case UPDATE_LEASE_DATA_SET:
        return {
            value: action.value && action.value.price_per_hour &&
                action.value.price_per_hour.amount &&
                ((action.value.price_per_hour.amount) / (10 ** config.COIN_DECIMALS)),
        };
    case REGISTER_LEASE_DIALOG_HIDE:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const registerLeaseDepositAmount = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case REGISTER_LEASE_DEPOSIT_AMOUNT_VALUE_SET:
        return {
            value: action.value,
        };
    case UPDATE_LEASE_DATA_SET:
        return {
            value: action.value && action.value.total_deposit &&
                action.value.total_deposit.amount &&
                ((action.value.total_deposit.amount) / (10 ** config.COIN_DECIMALS)),
        };
    case REGISTER_LEASE_DIALOG_HIDE:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const registerLeaseMoniker = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case REGISTER_LEASE_MONIKER_VALUE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case UPDATE_LEASE_DATA_SET:
        return {
            value: action.value && action.value.moniker,
            valid: true,
        };
    case REGISTER_LEASE_DIALOG_HIDE:
        return {
            value: '',
            valid: true,
        };
    default:
        return state;
    }
};

const registerLeaseDescription = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case REGISTER_LEASE_DESCRIPTION_VALUE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case UPDATE_LEASE_DATA_SET:
        return {
            value: action.value && action.value.description,
        };
    case REGISTER_LEASE_DIALOG_HIDE:
        return {
            value: '',
            valid: true,
        };
    default:
        return state;
    }
};

const registerLeaseContact = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case REGISTER_LEASE_CONTACT_VALUE_SET:
        return {
            value: action.value,
        };
    case UPDATE_LEASE_DATA_SET:
        return {
            value: action.value && action.value.contact,
        };
    case REGISTER_LEASE_DIALOG_HIDE:
        return {
            value: '',
        };
    default:
        return state;
    }
};

export default combineReducers({
    leaseButtonValue,
    leaseList,
    LeaseDialog,
    hours,

    deleteLeaseDialog,
    registerLeaseDialog,
    depositLeaseData,
    updateLeaseData,
    registerLeaseURL,
    registerLeaseCPUS,
    registerLeaseRamGB,
    registerLeaseStorageGB,
    registerLeaseDenom,
    registerLeaseAmount,
    registerLeaseDepositAmount,
    registerLeaseMoniker,
    registerLeaseDescription,
    registerLeaseContact,
});
