export const PUBLISH_CONTRACT_IV_TAB_SET = 'PUBLISH_CONTRACT_IV_TAB_SET';

export const PUBLISHED_ASSETS_FETCH_IN_PROGRESS = 'PUBLISHED_ASSETS_FETCH_IN_PROGRESS';
export const PUBLISHED_ASSETS_FETCH_SUCCESS = 'PUBLISHED_ASSETS_FETCH_SUCCESS';
export const PUBLISHED_ASSETS_FETCH_ERROR = 'PUBLISHED_ASSETS_FETCH_ERROR';

export const PUBLISHED_ASSET_FETCH_IN_PROGRESS = 'PUBLISHED_ASSET_FETCH_IN_PROGRESS';
export const PUBLISHED_ASSET_FETCH_SUCCESS = 'PUBLISHED_ASSET_FETCH_SUCCESS';
export const PUBLISHED_ASSET_FETCH_ERROR = 'PUBLISHED_ASSET_FETCH_ERROR';

export const PUBLISH_ID_SET = 'PUBLISH_ID_SET';
