export const FETCH_CONTRACTS_LIST_SUCCESS = 'FETCH_CONTRACTS_LIST_SUCCESS';
export const FETCH_CONTRACTS_LIST_IN_PROGRESS = 'FETCH_CONTRACTS_LIST_IN_PROGRESS';
export const FETCH_CONTRACTS_LIST_ERROR = 'FETCH_CONTRACTS_LIST_ERROR';

export const CONTRACTS_LIST_SEARCH_VALUE_SET = 'CONTRACTS_LIST_SEARCH_VALUE_SET';

export const CREATE_CONTRACT_SUB_TAB_SET = 'CREATE_CONTRACT_SUB_TAB_SET';
export const CLEAR_CREATE_CONTRACT_DATA = 'CLEAR_CREATE_CONTRACT_DATA';

// Collection Setup
export const COLLECTION_PREVIEW_URL_SET = 'COLLECTION_PREVIEW_URL_SET';
export const COLLECTION_NAME_SET = 'COLLECTION_NAME_SET';
export const COLLECTION_SYMBOL_SET = 'COLLECTION_SYMBOL_SET';
export const COLLECTION_DESCRIPTION_SET = 'COLLECTION_DESCRIPTION_SET';
export const COLLECTION_COLLABORATION_STATUS_SET = 'COLLECTION_COLLABORATION_STATUS_SET';
export const COLLECTION_COLLABORATION_VALUE_SET = 'COLLECTION_COLLABORATION_VALUE_SET';
export const COLLECTION_SCHEMA_JSON_SET = 'COLLECTION_SCHEMA_JSON_SET';
export const COLLECTION_SCHEME_JSON_TAB_SET = 'COLLECTION_SCHEME_JSON_TAB_SET';

// Mint Configuration
export const MINT_CONFIGURATION_NFT_TITLE_SET = 'MINT_CONFIGURATION_NFT_TITLE_SET';
export const MINT_CONFIGURATION_DESCRIPTION_SET = 'MINT_CONFIGURATION_DESCRIPTION_SET';
export const MINT_CONFIGURATION_MEDIA_URL_SET = 'MINT_CONFIGURATION_MEDIA_URL_SET';
export const MINT_CONFIGURATION_PREVIEW_URL_SET = 'MINT_CONFIGURATION_PREVIEW_URL_SET';
export const MINT_CONFIGURATION_ADMIN_ADDRESS_SET = 'MINT_CONFIGURATION_ADMIN_ADDRESS_SET';
export const MINT_CONFIGURATION_PAYMENT_COLLECTOR_SET = 'MINT_CONFIGURATION_PAYMENT_COLLECTOR_SET';
export const MINT_CONFIGURATION_MINT_PER_ADDRESS_SET = 'MINT_CONFIGURATION_MINT_PER_ADDRESS_SET';
export const MINT_CONFIGURATION_TOTAL_SUPPLY_SET = 'MINT_CONFIGURATION_TOTAL_SUPPLY_SET';
export const MINT_CONFIGURATION_TRANSFERABILITY_SWITCH_SET = 'MINT_CONFIGURATION_TRANSFERABILITY_SWITCH_SET';
export const MINT_CONFIGURATION_EXTENSIBILITY_SWITCH_SET = 'MINT_CONFIGURATION_EXTENSIBILITY_SWITCH_SET';
export const MINT_CONFIGURATION_NSFW_SWITCH_SET = 'MINT_CONFIGURATION_NSFW_SWITCH_SET';
export const MINT_CONFIGURATION_ROYALTY_SWITCH_SET = 'MINT_CONFIGURATION_ROYALTY_SWITCH_SET';
export const MINT_CONFIGURATION_ROYALTY_VALUE_SET = 'MINT_CONFIGURATION_ROYALTY_VALUE_SET';
export const MINT_CONFIGURATION_START_TIME_SET = 'MINT_CONFIGURATION_START_TIME_SET';
export const MINT_CONFIGURATION_END_TIME_SET = 'MINT_CONFIGURATION_END_TIME_SET';
export const MINT_CONFIGURATION_PRICE_PER_MINT_SET = 'MINT_CONFIGURATION_PRICE_PER_MINT_SET';
export const MINT_CONFIGURATION_SELECT_TOKEN_SET = 'MINT_CONFIGURATION_SELECT_TOKEN_SET';
export const MINT_CONFIGURATION_MINT_ADMIN_ADDRESS_SET = 'MINT_CONFIGURATION_MINT_ADMIN_ADDRESS_SET';
export const MINT_CONFIGURATION_SCHEMA_VALUES_SET = 'MINT_CONFIGURATION_SCHEMA_VALUES_SET';
export const MAKE_IT_FREE_SWITCH_SET = 'MAKE_IT_FREE_SWITCH_SET';
export const SHOW_MINT_CONFIGURATION_PREVIEW_DIALOG = 'SHOW_PARTICIPATION_NFT_PREVIEW_DIALOG';
export const HIDE_MINT_CONFIGURATION_PREVIEW_DIALOG = 'HIDE_PARTICIPATION_NFT_PREVIEW_DIALOG';

// Whitelist Setup
export const WHITELIST_SETUP_DETAILS_SET = 'WHITELIST_SETUP_DETAILS_SET';
export const WHITELIST_SETUP_ROUND_ADD = 'WHITELIST_SETUP_ROUND_ADD';
export const WHITELIST_ADMIN_ADDRESS_SET = 'WHITELIST_ADMIN_ADDRESS_SET';
export const WHITELIST_CONTRACT_ADDRESS_SET = 'WHITELIST_CONTRACT_ADDRESS_SET';
export const WHITELIST_CONTRACT_SELECTION_SET = 'WHITELIST_CONTRACT_SELECTION_SET';

// Success Dialog
export const OEM_CREATION_SUCCESS_DIALOG_SHOW = 'OEM_CREATION_SUCCESS_DIALOG_SHOW';
export const OEM_CREATION_SUCCESS_DIALOG_HIDE = 'OEM_CREATION_SUCCESS_DIALOG_HIDE';

// Contract Details Fetch
export const FETCH_MINTER_CONTRACT_DETAILS_IN_PROGRESS = 'FETCH_MINTER_CONTRACT_DETAILS_IN_PROGRESS';
export const FETCH_MINTER_CONTRACT_DETAILS_SUCCESS = 'FETCH_MINTER_CONTRACT_DETAILS_SUCCESS';
export const FETCH_MINTER_CONTRACT_DETAILS_ERROR = 'FETCH_MINTER_CONTRACT_DETAILS_ERROR';

export const FETCH_WHITELIST_CONTRACTS_LIST_SUCCESS = 'FETCH_WHITELIST_CONTRACTS_LIST_SUCCESS';
export const FETCH_WHITELIST_CONTRACTS_LIST_IN_PROGRESS = 'FETCH_WHITELIST_CONTRACTS_LIST_IN_PROGRESS';
export const FETCH_WHITELIST_CONTRACTS_LIST_ERROR = 'FETCH_WHITELIST_CONTRACTS_LIST_ERROR';

export const FETCH_WHITELIST_CONTRACT_DETAILS_IN_PROGRESS = 'FETCH_WHITELIST_CONTRACT_DETAILS_IN_PROGRESS';
export const FETCH_WHITELIST_CONTRACT_DETAILS_SUCCESS = 'FETCH_WHITELIST_CONTRACT_DETAILS_SUCCESS';
export const FETCH_WHITELIST_CONTRACT_DETAILS_ERROR = 'FETCH_WHITELIST_CONTRACT_DETAILS_ERROR';

export const WHITELIST_PREVIOUS_CONTRACT_SET = 'WHITELIST_PREVIOUS_CONTRACT_SET';

export const TOTAL_SUPPLY_TAB_VALUE_SET = 'TOTAL_SUPPLY_TAB_VALUE_SET';

export const MMOEM_COLLECTION_CONTRACT_ADDRESS_SET = 'MMOEM_COLLECTION_CONTRACT_ADDRESS_SET';
export const MMOEM_MINT_EDITION_ROUND_ADD = 'MMOEM_MINT_EDITION_ROUND_ADD';
export const MMOEM_MINT_EDITION_ROUND_DETAILS_SET = 'MMOEM_MINT_EDITION_ROUND_DETAILS_SET';
