import { combineReducers } from 'redux';
import {
    PUBLISH_CONTRACT_IV_TAB_SET,
    PUBLISH_ID_SET,
    PUBLISHED_ASSET_FETCH_ERROR,
    PUBLISHED_ASSET_FETCH_IN_PROGRESS,
    PUBLISHED_ASSET_FETCH_SUCCESS,
    PUBLISHED_ASSETS_FETCH_ERROR,
    PUBLISHED_ASSETS_FETCH_IN_PROGRESS,
    PUBLISHED_ASSETS_FETCH_SUCCESS,
} from '../constants/assetPublish';
import { DEFAULT_LIMIT, DEFAULT_SKIP, DEFAULT_TOTAL } from '../config';

const publishContractIVTab = (state = 'edit', action) => {
    if (action.type === PUBLISH_CONTRACT_IV_TAB_SET) {
        return action.value;
    }

    return state;
};

const publishedAssets = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: DEFAULT_TOTAL,
}, action) => {
    switch (action.type) {
    case PUBLISHED_ASSETS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PUBLISHED_ASSETS_FETCH_SUCCESS:
        return {
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
        };
    case PUBLISHED_ASSETS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const publishedAsset = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case PUBLISHED_ASSET_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PUBLISHED_ASSET_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case PUBLISHED_ASSET_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const publishID = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case PUBLISH_ID_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

export default combineReducers({
    publishContractIVTab,
    publishedAssets,
    publishedAsset,
    publishID,
});
