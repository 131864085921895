export const LEASE_BUTTON_VALUE_SET = 'LEASE_BUTTON_VALUE_SET';

export const LEASE_LIST_FETCH_IN_PROGRESS = 'LEASE_LIST_FETCH_IN_PROGRESS';
export const LEASE_LIST_FETCH_SUCCESS = 'LEASE_LIST_FETCH_SUCCESS';
export const LEASE_LIST_FETCH_ERROR = 'LEASE_LIST_FETCH_ERROR';

export const ADD_LEASE_DIALOG_SHOW = 'ADD_LEASE_DIALOG_SHOW';
export const ADD_LEASE_DIALOG_HIDE = 'ADD_LEASE_DIALOG_HIDE';
export const DELETE_LEASE_DIALOG_SHOW = 'DELETE_LEASE_DIALOG_SHOW';
export const DELETE_LEASE_DIALOG_HIDE = 'DELETE_LEASE_DIALOG_HIDE';
export const UPDATE_LEASE_DATA_SET = 'UPDATE_LEASE_DATA_SET';
export const DEPOSIT_LEASE_DATA_SET = 'DEPOSIT_LEASE_DATA_SET';

export const LEASE_HOURS_SET = 'LEASE_HOURS_SET';

export const REGISTER_LEASE_DIALOG_SHOW = 'REGISTER_LEASE_DIALOG_SHOW';
export const REGISTER_LEASE_DIALOG_HIDE = 'REGISTER_LEASE_DIALOG_HIDE';

export const REGISTER_LEASE_URL_VALUE_SET = 'REGISTER_LEASE_URL_VALUE_SET';
export const REGISTER_LEASE_CPUS_VALUE_SET = 'REGISTER_LEASE_CPUS_VALUE_SET';
export const REGISTER_LEASE_RAM_GB_VALUE_SET = 'REGISTER_LEASE_RAM_GB_VALUE_SET';
export const REGISTER_LEASE_STORAGE_GB_VALUE_SET = 'REGISTER_LEASE_STORAGE_GB_VALUE_SET';
export const REGISTER_LEASE_DENOM_VALUE_SET = 'REGISTER_LEASE_DENOM_VALUE_SET';
export const REGISTER_LEASE_AMOUNT_VALUE_SET = 'REGISTER_LEASE_AMOUNT_VALUE_SET';
export const REGISTER_LEASE_DEPOSIT_AMOUNT_VALUE_SET = 'REGISTER_LEASE_DEPOSIT_AMOUNT_VALUE_SET';
export const REGISTER_LEASE_MONIKER_VALUE_SET = 'REGISTER_LEASE_MONIKER_VALUE_SET';
export const REGISTER_LEASE_DESCRIPTION_VALUE_SET = 'REGISTER_LEASE_DESCRIPTION_VALUE_SET';
export const REGISTER_LEASE_CONTACT_VALUE_SET = 'REGISTER_LEASE_CONTACT_VALUE_SET';
