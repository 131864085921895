import { combineReducers } from 'redux';
import {
    PROFILE_PICTURE_SUCCESS,
    PROFILE_PICTURE_IN_PROGRESS,
    PROFILE_PICTURE_ERROR,

    PROFILE_PICTURE_RESTORE,
    AVATAR_UPLOAD_ERROR,
    AVATAR_UPLOAD_IN_PROGRESS,
    AVATAR_UPLOAD_SUCCESS,
    PROFILE_PICTURE_DIALOG_HIDE,
    PROFILE_PICTURE_DIALOG_SHOW,
    USER_OWNED_NFTS_IN_PROGRESS,
    USER_OWNED_NFTS_SUCCESS,
    USER_OWNED_NFTS_ERROR,
    UPDATE_USER_OWNED_NFTS_SEARCH,
} from '../../constants/account';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../config';

const getProfilePicture = (state = {
    inProgress: false,
    value: null,
    error: null,
}, action) => {
    switch (action.type) {
    case PROFILE_PICTURE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PROFILE_PICTURE_SUCCESS:
        return {
            inProgress: false,
            value: action.result,
            error: null,
        };
    case PROFILE_PICTURE_ERROR:
        return {
            ...state,
            inProgress: false,
            value: null,
            error: action.message,
        };
    default:
        return state;
    }
};

const profilePictureUpload = (state = {
    inProgress: false,
    value: null,
    error: null,
}, action) => {
    switch (action.type) {
    case PROFILE_PICTURE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PROFILE_PICTURE_SUCCESS:
        return {
            inProgress: false,
            value: action.result,
            error: null,
        };
    case PROFILE_PICTURE_RESTORE:
        return {
            inProgress: false,
            value: null,
            error: null,
        };
    case PROFILE_PICTURE_ERROR:
        return {
            ...state,
            inProgress: false,
            value: null,
            error: action.message,
        };
    default:
        return state;
    }
};

const avatar = (state = {
    inProgress: false,
    value: null,
}, action) => {
    switch (action.type) {
    case AVATAR_UPLOAD_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case AVATAR_UPLOAD_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case AVATAR_UPLOAD_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const profilePictureDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case PROFILE_PICTURE_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case PROFILE_PICTURE_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

const userOwnedNFTs = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
    address: '',
    searchKey: '',
}, action) => {
    switch (action.type) {
    case USER_OWNED_NFTS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case USER_OWNED_NFTS_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.result,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
                address: action.address,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.result],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
                address: action.address,
            };
        }
    }
    case USER_OWNED_NFTS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case UPDATE_USER_OWNED_NFTS_SEARCH:
        return {
            ...state,
            searchKey: action.searchKey,
        };
    default:
        return state;
    }
};

export default combineReducers({
    getProfilePicture,
    profilePictureUpload,
    avatar,
    profilePictureDialog,
    userOwnedNFTs,
});
